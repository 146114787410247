import {
  Banner,
  Box,
  Container,
  Heading,
  Helmet,
  Layout,
  Link,
  NavigationTiles,
  Panel,
  ReadMoreLink,
  Row,
  Section,
  Theme,
  YouTubeVideo,
  iconSelector,
  routesObject
} from "@life-without-barriers/react-components"
import {
  ContentNodes,
  Resource,
  Site,
  YouTubeVideo as YouTubeContent,
  firstNode,
  firstNodes,
  getGatsbyImage
} from "@life-without-barriers/gatsby-common"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { NavJson, ResourcesJson } from "../../../contentTypes"

import React from "react"
import { graphql } from "gatsby"
import { renderFeaturedResources } from "../../../components/social-policy/contentRenderer"
import { stripVideoIdFromYoutubeUrl } from "@life-without-barriers/utilities"
import styled from "styled-components"

const { aboriginalAndTorresStraitIslanderPeoplesThemeFull } = Theme

interface FeaturedPanelProps {
  heading: string
  image: IGatsbyImageData
  imageAttribution?: string
  text: string[]
}

const StyledInlineImage = styled(GatsbyImage)`
  width: 100% !important;
`

const FeaturedPanel = ({
  heading,
  image,
  imageAttribution,
  text
}: FeaturedPanelProps) => {
  const textParagraphs = text.map((t, i) => (
    <p className="copy" key={i}>
      {t}
    </p>
  ))
  return (
    <Row className="flex-wrap">
      <Box className="w-50-l w-100 mb4">
        <Panel background="bg-lwb-white" className="pt4 h-100 w-80 mt4">
          <Heading size={2}>{heading}</Heading>
          {textParagraphs}
          {imageAttribution && <p className="i copy">{imageAttribution}</p>}
        </Panel>
      </Box>
      <Box className="w-50-l w-100 flex items-center justify-center">
        <StyledInlineImage
          className="w-100"
          alt={""}
          image={getGatsbyImage(image)}
          style={{ width: "100%", maxWidth: "540px" }}
        />
      </Box>
    </Row>
  )
}

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    openGraphImage: IGatsbyImageData
    featurePanelImage: IGatsbyImageData
    elevateRAP2022?: ContentNodes<Resource>
    elevateRAPSummary2022?: ContentNodes<Resource>
    artworkStory?: ContentNodes<Resource>
    uluruVoicePositionStatement?: ContentNodes<Resource>
    uluruVideoNode?: ContentNodes<YouTubeContent>
    resources: ResourcesJson
    standaloneResource: ResourcesJson
    navs: NavJson
  }
}

const IndexPage = ({
  location,
  data: {
    site: { siteMetadata },
    openGraphImage,
    elevateRAP2022,
    elevateRAPSummary2022,
    artworkStory,
    uluruVoicePositionStatement,
    uluruVideoNode,
    featurePanelImage,
    navs
  }
}: Props) => {
  // Don't show ourselves in the sub nav.
  const navItems = navs.edges[0].node.items.filter((item) => item.href !== "")
  const uluruVideo = firstNode(uluruVideoNode)
  const uluruPositionStatementUrl = (
    firstNode(uluruVoicePositionStatement) as Resource
  )?.pdfAsset?.file?.url
  return (
    <div>
      <Helmet
        title={`Aboriginal & Torres Strait Islander Peoples | ${siteMetadata.title}`}
        description="We aim to close the gap in life expectancy by improving the cultural, spiritual and emotional well-being of Aboriginal and Torres Strait Islander peoples"
        image={openGraphImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout theme={aboriginalAndTorresStraitIslanderPeoplesThemeFull}>
        <Banner
          title="Aboriginal and Torres Strait Islander peoples"
          breadcrumbs={[
            routesObject.home,
            {
              title: "Social policy",
              text: "Social policy",
              to: "/social-policy"
            }
          ]}
        />
        <Section background="bg-lwb-black">
          <Container>
            <FeaturedPanel
              heading="Our commitment towards truth telling and Reconciliation"
              image={featurePanelImage}
              imageAttribution={
                "'Empowered Voices' artwork created by Keisha Leon (Waanyi and Kalkadoon), Lisa Sorbie Martin (Moa and Murry Islands, Torres Strait Islands), and Gilimbaa."
              }
              text={[
                "Life Without Barriers acknowledges Aboriginal and Torres Strait Islander peoples as the Traditional Owners and Custodians of this country and acknowledges their connection to land, water and community. We pay our respect to them, their cultures and customs and to their Elders past, present and emerging. We believe that Reconciliation must live in the hearts and minds of all Australians. We are committed to an ongoing journey towards Truth Telling, Self Determination and Reconciliation.",
                "We recognise and value the contribution of Aboriginal and Torres Strait Islander employees, carers, people we support, partners and communities across Australia as we continue our Reconciliation journey together."
              ]}
            />
          </Container>
        </Section>
        {renderFeaturedResources({
          title: "Life Without Barriers Elevate Reconciliation Action Plan",
          resources: firstNodes([
            elevateRAP2022,
            elevateRAPSummary2022,
            artworkStory
          ]),
          contentBlock: (
            <p>
              Life Without Barriers&apos; commitments to Reconciliation are
              outlined in our Elevate Reconciliation Action Plan (RAP). The RAP
              includes our Transformation Project which is our commitment to
              progressively step away from providing out-of-home care to
              Aboriginal and Torres Strait Islander children over the next ten
              years by transitioning them into the more culturally safe and
              appropriate care of family, community and Aboriginal Community
              Controlled Organisations.
            </p>
          )
        })}
        <Section background="bg-lwb-white">
          <Container>
            <Row className="flex-wrap">
              <Box className="w-100 layout-gap">
                <Heading size={2}>
                  We support the Uluru Statement from the Heart
                </Heading>
                <p className="layout-readable copy">
                  The{" "}
                  <Link to="https://ulurustatement.org/">
                    Uluru Statement from the Heart
                  </Link>{" "}
                  is an invitation to all Australian’s, encouraging us to come
                  together to support the establishment of a ‘First Nations
                  Voice’ to be codified and enshrined in the Australian
                  Constitution.
                </p>
                <p className="layout-readable copy">
                  Life Without Barriers’ supports the Uluru Statement from the
                  Heart in its entirety.
                </p>
                <p className="layout-readable copy">
                  The primary source of law in Australia is the Constitution
                  which currently does not recognise Aboriginal and Torres
                  Strait Islander people as the first people of Australia. The
                  Constitution was formed without any consultation or
                  recognition of Aboriginal and Torres Strait Islander peoples.
                </p>
                <p className="layout-readable copy">
                  Life Without Barriers supports the Constitutional recognition
                  of Aboriginal and Torres Strait Islander people as described
                  in the Uluru Statement from the Heart
                </p>
                {uluruPositionStatementUrl && (
                  <ReadMoreLink
                    text={
                      "Read our Uluru Statement from the Heart Position Statement"
                    }
                    href={uluruPositionStatementUrl}
                    className="mt3"
                  />
                )}
              </Box>
              <Box className="w-100 layout-readable">
                {uluruVideo && (
                  <YouTubeVideo
                    className="mt4"
                    title={uluruVideo.title}
                    videoId={stripVideoIdFromYoutubeUrl(uluruVideo.url) || ""}
                  />
                )}
              </Box>
            </Row>
          </Container>
        </Section>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row>
              <Box>
                <NavigationTiles
                  isLandscape={true}
                  isThreeCol={false}
                  selectIconFn={iconSelector}
                  pages={navItems.map((v) => ({ ...v, iconHeight: "75" }))}
                  currentPathname=""
                  heading={
                    <Heading size={2} className="color-lwb-black">
                      Explore our work
                    </Heading>
                  }
                />
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    openGraphImage: file(relativePath: { regex: "/index-og.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    featurePanelImage: file(relativePath: { regex: "/LWB_Artwork_FULL.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 540, placeholder: BLURRED)
      }
    }
    standaloneResource: allResourcesJson(
      filter: { title: { eq: "Reconciliation Australia" } }
    ) {
      edges {
        node {
          title
          copy
          mobileCopy
          href
          external
          imageObjectFit
          image {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 90)
            }
          }
        }
      }
    }
    elevateRAP2022: allContentfulResource(
      filter: { title: { regex: "/Elevate RAP 2022/i" } }
    ) {
      edges {
        node {
          ...ContentfulResource
        }
      }
    }
    elevateRAPSummary2022: allContentfulResource(
      filter: { title: { regex: "/Elevate RAP Summary 2022/i" } }
    ) {
      edges {
        node {
          ...ContentfulResource
        }
      }
    }
    artworkStory: allContentfulResource(
      filter: { title: { regex: "/Artwork story - Empowered Voices/i" } }
    ) {
      edges {
        node {
          ...ContentfulResource
        }
      }
    }
    uluruVoicePositionStatement: allContentfulResource(
      filter: {
        title: {
          regex: "/Uluru Statement from the Heart - Position Statement/i"
        }
      }
    ) {
      edges {
        node {
          ...ContentfulResource
        }
      }
    }
    uluruVideoNode: allContentfulYouTubeVideo(
      filter: { title: { eq: "Uluru Statement from the Heart" } }
    ) {
      edges {
        node {
          url
          title
        }
      }
    }
    navs: allNavJson {
      edges {
        node {
          title
          items {
            title
            href
            copy
            icon
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default IndexPage
