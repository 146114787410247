import {
  Container,
  PromotedResources,
  Section
} from "@life-without-barriers/react-components"

import React from "react"
import { Resource } from "@life-without-barriers/gatsby-common"

export const renderFeaturedResources = ({
  title,
  resources,
  contentBlock,
  background
}: {
  title?: string
  resources: Resource[]
  contentBlock?: React.ReactElement | React.ReactElement[]
  background?:
    | "bg-lwb-white"
    | "bg-lwb-grey-xxx-light"
    | "bg-lwb-theme-xxx-light"
    | "bg-lwb-theme-light"
}) => (
  <Section background={background || "bg-lwb-grey-xxx-light"}>
    <Container>
      <PromotedResources
        heading={title}
        cards={resources
          .filter((node): node is Resource => !!node)
          .map(({ title, image, pdfAsset, url, description }) => ({
            title,
            image: image.gatsbyImageData,
            href: pdfAsset?.file.url ?? url ?? "",
            copy: description.childMarkdownRemark.rawMarkdownBody
          }))}
      >
        {contentBlock}
      </PromotedResources>
    </Container>
  </Section>
)
